import React, { createContext, useState, useCallback } from 'react';
import { MODES } from '../lib/constants';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [mode, setMode] = useState(user !== null ? MODES.LOGGED_IN : MODES.UNDETERMINED);

    // for notification cell
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("");

    const showNotification = useCallback(({ message:newMessage, type:newType, duration }) => {
        setNotificationType(newType || '');
        setMessage(newMessage || '');
        const timeToShow = duration || 5000;
        setTimeout(() => {
            setMessage('');
            setNotificationType('');
        }, timeToShow)
    }, [setMessage, setNotificationType]);

    return (
        <AppContext.Provider
            value={{
                user,
                setUser,
                mode,
                setMode,
                message,
                notificationType,
                showNotification
            }}
        >
            {children}
        </AppContext.Provider>
    );
};