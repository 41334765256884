const MODES = {
    ADMIN: 5,
    LOGGED_IN: 1,
    LOGGING_IN: 2,
    PASSWORD_RESET_REQUEST: 6,
    SIGNING_UP: 3,
    UNDETERMINED: 4
}

const RESET_MODES = {
    LOADING: 1,
    NO_TOKEN: 2,
    TOKEN_FOUND: 3,
    INVALID_TOKEN: 4
}

const TICKET_PRICE = 5;

export {
    MODES,
    RESET_MODES,
    TICKET_PRICE
}